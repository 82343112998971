import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Attributes } from 'components/template-designer/types/attribute.type';
import Layer from 'components/template-designer/types/layer.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import Template, { DesignerSettings, View } from 'components/template-designer/types/template.type';
import Dialog from 'components/ui-components-v2/Dialog';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { DynamicLayerInputDialogLayers } from './components/dynamic-layer-input-dialog-layers';
import { DynamicLayerInputDialogAttributes } from './components/dynamic-layer-input-dialog-attributes';
import { DynamicLayerInputDialogPreviewLayer } from './components/dynamic-layer-input-dialog-preview-layer';
import { DialogActions, DialogTitle } from '../ui-components/dialog';
import EmptyState from '../ui-components/empty-state';
import './styles/main.scss';

export type Selected = Record<Layer['key'], Attributes[]>;

export const formatData = {
    children: [],
    type: 'format',
    key: 'formatData',
    title: Translation.get('general.labels.format', 'template-designer')
};

interface Props {
    onClose: () => void;
    onSubmit: (selected: Selected) => void;
    title: string;
    alreadySelected: Selected;
    showFormat?: boolean;
    showLinkedInputs?: boolean;
}

const DynamicLayerInputDialog = ({ onClose, onSubmit, title, alreadySelected, showFormat, showLinkedInputs }: Props) => {
    const { layers, frameType, enableAnimations, enableLottie } = useComponentStore<{
        layers: Template['layers'];
        frameType: View['frameType'];
        enableAnimations: DesignerSettings['enableAnimations'];
        enableLottie: DesignerSettings['enableLottie'];
    }>('TemplateDesigner', {
        fields: {
            layers: 'layers',
            frameType: 'view.frameType',
            enableAnimations: 'designerSettings.enableAnimations',
            enableLottie: 'designerSettings.enableLottie'
        }
    });

    const ORIGINAL_LEFT_SIDE_WIDTH = 250;
    const [dynamicLayerInputLayerWidth, setDynamicLayerInputLayerWidth] = useState<number>(ORIGINAL_LEFT_SIDE_WIDTH);

    const layersShouldNotReverse = useMemo(() => TemplateVersionHelpers.layersShouldNotReverse(), []);

    const currentLayers = useMemo(() => {
        const filteredLayers = LayerHelpers.getLayers(cloneDeep(layers), frameType, enableAnimations, enableLottie, !layersShouldNotReverse);

        if (showFormat) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filteredLayers.unshift(formatData);
        }

        return filteredLayers;
    }, [layers, frameType, enableAnimations, enableLottie, layersShouldNotReverse]);

    const [selectedLayer, setSelectedLayer] = useState<Layer>(
        (() => {
            if (currentLayers.length > 1) {
                return currentLayers[1];
            }

            return currentLayers[0];
        })()
    );
    const [selected, setSelected] = useState<Selected>({});

    /**
     * Handle selected change.
     * @param layerKey - Layer key.
     * @param attribute - Attribute.
     */
    const onSelectedChange = (layerKey: Layer['key'], attribute: Attributes): void => {
        setSelected((prevSelected) => {
            if (prevSelected[layerKey] && prevSelected[layerKey].includes(attribute)) {
                return {
                    ...prevSelected,
                    [layerKey]: prevSelected[layerKey].filter((selectedAttribute) => selectedAttribute !== attribute)
                };
            }

            return {
                ...prevSelected,
                [layerKey]: [...(prevSelected[layerKey] || []), attribute]
            };
        });
    };

    const totalSelected = Object.values(selected).reduce((acc, curr) => acc + curr.length, 0);

    return (
        <Dialog className="template-designer__dynamic-layer-input-dialog" open maxWidth="xl" onClose={onClose}>
            <DialogTitle onClose={onClose}>{title}</DialogTitle>

            {currentLayers.length === 0 && (
                <div
                    className={classNames('template-designer__dynamic-layer-input-dialog__content', {
                        ['template-designer__dynamic-layer-input-dialog__content--empty']: currentLayers.length === 0
                    })}>
                    <EmptyState
                        headline={Translation.get('dynamicLayers.dynamicLayerInput.empty.headline', 'template-designer')}
                        icon="placeholder-dynamic-layers"
                    />
                </div>
            )}

            {currentLayers.length > 0 && (
                <>
                    <div className="template-designer__dynamic-layer-input-dialog__content">
                        <DynamicLayerInputDialogLayers
                            layers={currentLayers}
                            selected={selected}
                            selectedLayer={selectedLayer}
                            width={dynamicLayerInputLayerWidth}
                            setSelectedLayer={setSelectedLayer}
                            setDynamicLayerInputLayerWidth={setDynamicLayerInputLayerWidth}
                        />
                        <DynamicLayerInputDialogAttributes
                            selectedLayer={selectedLayer}
                            selected={selected}
                            alreadySelected={alreadySelected}
                            showLinkedInputs={showLinkedInputs}
                            leftSideWidthDifference={ORIGINAL_LEFT_SIDE_WIDTH - dynamicLayerInputLayerWidth}
                            onSelectionChange={onSelectedChange}
                        />
                        <DynamicLayerInputDialogPreviewLayer layer={selectedLayer} frameType={frameType} />
                    </div>
                    <DialogActions className="template-designer__dynamic-layer-input-dialog__actions" alignRight>
                        <div className="template-designer__dynamic-layer-input-dialog__selected">
                            {totalSelected === 1
                                ? Translation.get('dynamicLayers.dynamicLayerInput.totalSelected_one', 'template-designer', {
                                      total: totalSelected
                                  })
                                : Translation.get('dynamicLayers.dynamicLayerInput.totalSelected_other', 'template-designer', {
                                      total: totalSelected
                                  })}
                        </div>
                        <Button
                            variant="contained"
                            onClick={() => onSubmit(selected)}
                            disabled={totalSelected === 0}
                            data-cy="templateDesigner-dynamicLayerDialogAdd-button">
                            {title}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export { DynamicLayerInputDialog };
